import React, { Fragment, useState } from "react"
import ModalVideo from "react-modal-video"
import "react-modal-video/css/modal-video.min.css"

const AranduAngaVideo = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Fragment>
      <a 
        className="blue_btn PD-pink-2" 
        href="#" 
        onClick={e => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        <span>Vídeo apresentação</span>
      </a>
      {(typeof(window) !== 'undefined') && <ModalVideo
        channel="vimeo"
        autoplay
        isOpen={isOpen}
        videoId="943644396"
        onClose={() => setOpen(false)}
      /> }
    </Fragment>
  );
};

export default AranduAngaVideo;
